export const sleep = async (ms) => {
	return new Promise((resolve, reject) => {
		setTimeout(resolve, ms);
	});
};

export const tryParseBody = async (response) => {
	// Tries to parse the request body as JSON
	// This wrapper avoids situations where if a request
	// is not successful, the code will throw an error
	// if the response body is not JSON.
	try {
		return await response.json();
	} catch (error) {
		console.log('tryParseBody', { error });
		return undefined;
	}
};
